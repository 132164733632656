<template>
    <v-container>
        <v-card v-for="(item,i) in observations" :key="i" class="mb-4">
            <v-card-title class="timeline-date">{{ item.data.replace("T"," ") }}</v-card-title>
            <v-card-text>{{ item.mensagem }}</v-card-text>
        </v-card>
        <v-textarea 
            v-if="edit"
            outlined 
            v-model="mensagem"
            @change="setNewObservation(mensagem)"
        ></v-textarea>
    </v-container>
</template>

<script>
export default {
    name: "Observations",
    props: {
        observations: Array,
        edit: Boolean,
    },
    emits: ["setNewObservation"],
    data() {
        return {
            mensagem: ""
        }
    },
    methods: {
        setNewObservation(mensagem) {
            this.$emit("setNewObservation", mensagem);
        },
    },
}
</script>

<style scoped>
    .timeline-date {
    font-size: 14px;
    padding-bottom: 5px !important;
    }
</style>