<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="#c4c22c"
          fab
          x-small
          dark
          v-on="on"
          @click="changeVisualBtn(false)"
          ><v-icon>search</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-toolbar dark color="#c4c22c">
          <v-btn
            icon
            dark
            @click="
              dialog = false;
              changeVisualBtn(true);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn dark text @click="dialog = false"></v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <div class="reports">
          <h1>
            Lead Process ID: {{ lead.lead_process_id
            }}<span class="h1-underline"></span>
          </h1>
          <div class="container">
            <v-container>
              <v-row>
                <v-col :cols="8">
                  <v-card class="colunas">
                    <div v-for="(item, i) in contentData" :key="i">
                      <v-row class="px-5">
                        <v-col :cols="item.cols">
                          <h2 class="ml-0">
                            {{ item.title }}<span class="h2-underline"></span>
                          </h2>
                        </v-col>
                      </v-row>

                      <v-row class="px-5">
                        <v-col
                          v-for="(text, j) in item.content"
                          :key="j"
                          :cols="text.cols"
                          class="colunas-interiores py-1"
                          v-show="text.value != ''"
                        >
                          <div class="my-0 py-0">
                            <!-- SUBTITLE 01 -->
                            <p
                              class="
                                my-0
                                font-weight-bold
                                text-capitalize
                                subtitle-2
                              "
                            >
                              {{
                                text.title
                                  .replace(/___/g, "/")
                                  .replace(/__/g, "-")
                                  .replace(/_/g, " ")
                              }}:
                            </p>
                            <template
                              v-if="
                                text.title != 'dependentes__seguros' &&
                                text.title != 'banco'
                              "
                            >
                              <!-- {{text.title}} -->
                              <p
                                v-if="
                                  text.title == 'rendimentos_prediais' ||
                                  text.title == 'rendimento_liquido_mensal' ||
                                  text.title == 'rendimentos_variaveis' ||
                                  text.title == 'pensao_de_alimentos' ||
                                  text.title ==
                                    'rendimentos_nao_comprovaveis' ||
                                  text.title == 'rendimento_nao_comprovaveis' ||
                                  text.title == 'outros_rendimentos' ||
                                  text.title ==
                                    'proveniencia_de_outros_rendimentos' ||
                                  text.title == 'despesas_do_agregado' ||
                                  text.title ==
                                    'outros_creditos___rendimento' ||
                                  text.title == 'titulares_total' ||
                                  text.title == 'incumprimento' ||
                                  text.title ==
                                    'valor_do_financiamento___em_divida' ||
                                  text.title ==
                                    'valor_do_multiopcoes___obras' ||
                                  text.title == 'avaliacao_do_imovel' ||
                                  text.title == 'valor_escritura' ||
                                  text.title == 'resultado_da_avaliacao'
                                "
                                class="
                                  font-weight-light
                                  text-capitalize
                                  my-0
                                  caption
                                "
                              >
                                {{ text.value }}
                              </p>
                              <p
                                v-else
                                class="
                                  font-weight-light
                                  text-capitalize
                                  my-0
                                  caption
                                "
                              >
                                {{ text.value }}
                              </p>
                            </template>
                            <template
                              v-else-if="text.title == 'dependentes__seguros'"
                            >
                              <div
                                v-for="(val, k) in text.value"
                                :key="k"
                                class="
                                  font-weight-light
                                  my-0
                                  text-capitalize
                                  caption
                                  dependentes
                                "
                              >
                                <p v-if="val.name == 'sada'">
                                  Nome: {{ val.name }} <br />
                                  Data nascimento:
                                  {{ val.dataAniversario }} <br />
                                  NIF: {{ val.contribuinte }}
                                </p>
                              </div>
                            </template>

                            <template v-else-if="text.title == 'banco'">
                              <p
                                v-for="(val, k) in text.value"
                                :key="k"
                                class="
                                  font-weight-light
                                  my-0
                                  text-capitalize
                                  caption
                                  dependentes
                                "
                              >
                                <span v-if="val != ''">
                                  Banco: {{ val.banco }} <br />
                                  Balcão:
                                  {{ val.balcao }} <br />
                                  Data de Envio: {{ val.Data_de_Envio }} <br />
                                  Notas: {{ val.Notas }}
                                </span>
                              </p>
                            </template>
                          </div>

                          <!-- SUBTITLE 01 -->
                          <!-- <v-list-item v-if="text.value != ''">
                            <v-list-item-title>
                              <b class="sub-titulo-bold mb-2"
                                >{{
                                  text.title
                                    .replace(/___/g, "/")
                                    .replace(/__/g, "-")
                                    .replace(/_/g, " ")
                                }}:</b
                              >
                              <br />
                              {{ text.value }}
                            </v-list-item-title>
                          </v-list-item> -->

                          <!-- <h2
                            class="sub-titulos-ficha mb-4"
                            v-if="text.title != item.title"
                          >
                            {{ text.title }} <span class="h2-underline"></span>
                          </h2>


                          <div v-for="(c, k) in text.content" :key="k"> -->
                          <!-- LIST OF SUBTITLE 01 -->
                          <!-- <v-list-item v-if="c.content != ''">
                              <v-list-item-title>
                                <b class="sub-titulo-bold mb-2"
                                  >{{
                                    c.title
                                      .replace(/___/g, "/")
                                      .replace(/__/g, "-")
                                      .replace(/_/g, " ")
                                  }}:</b
                                >
                                <br />
                                {{ c.content }}
                              </v-list-item-title>
                            </v-list-item>
                          </div> -->
                        </v-col>
                      </v-row>
                    </div>

                    <!-- OBSERVAÇOES: -->

                    <v-row class="px-5">
                      <v-col cols="12">
                        <h2 class="ml-0">
                          Observações<span class="h2-underline"></span>
                        </h2>
                      </v-col>
                    </v-row>
                    <v-row class="px-5">
                      <v-col cols="12">
                        <Observations
                          :observations="observations"
                          :edit="false"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <!-- COLUNA HISTORICO -->
                <v-col :cols="4">
                  <v-card class="colunas">
                    <h2>Histórico<span class="h2-underline"></span></h2>
                    <v-timeline align-top dense class="timeline-container">
                      <v-timeline-item
                        v-for="(hist, i) in historico"
                        :key="i"
                        fill-dot
                        right
                        small
                      >
                        <v-card class="elevation-2">
                          <v-card-title class="timeline-date">{{
                            hist.data
                          }}</v-card-title>
                          <v-card-text>
                            <b>Resultado:</b> {{ hist.resultado }}
                            <br />
                            <b>Estado:</b> {{ hist.estado }}
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card>
                  <v-card class="colunas">
                    <h2>Outros dados<span class="h2-underline"></span></h2>
                    <p class="ml-10 mt-5">
                      Origem: {{ extraInfo.origem_lp }}
                      <br />
                      Consultor: {{ extraInfo.consultor_lp }}
                      <br />
                      URL: {{ extraInfo.url }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Observations from "@/components/Observations";

import { populateContentHelper } from "@/helpers/LeadDetailData/reportData.js";
import { leadComplete } from "@/helpers/LeadInsertData/modelCompleteLead.js";

const initialState = () => {
  return {
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,

    dadostitular1: [],
    rendimentostitular1: [],
    empregotitular1: [],
    creditotitular1: [],
    dadostitular2: [],
    rendimentostitular2: [],
    empregotitular2: [],
    creditotitular2: [],

    item: 1,
    items: [],
    historico: [],
    extraInfo: {},
    contentData: "",
    leadSelected: "",
  };
};

export default {
  name: "LeadDetail",
  components: {
    Observations,
  },
  props: ["lead"],
  data() {
    return initialState();
  },
  methods: {
    changeVisualBtn: function (cond) {
      this.$store.commit("SET_BTN_EXPORT_EXCEL", cond);
    },
    collectLeadData: async function () {
      await this.$store.dispatch(
        "collect_lead_process",
        this.lead.lead_process_id
      );

      this.historico = this.$store.state.single_lead_process_id;
    },
    collectExtraLeadData: async function () {
      await this.$store.dispatch("collect_extra_lead_data", this.lead.lead_id);

      let extraInfo = this.$store.state.single_lead_center_process_id[0];
      if (extraInfo.consultor_lp == null && extraInfo.origem_lp == null)
        extraInfo.origem_lp = "Lead Center";
      this.extraInfo = extraInfo;
    },
    getAllContentDetails: async function () {
      let lead = this.completeLead;
      let type = this.typeLead(lead);
      this.contentData = await populateContentHelper(type, lead);
    },
    typeLead: function (lead) {
      let type = lead.produto;

      switch (type) {
        case "credito_consolidado":
          return "CC";
        case "credito_pessoal":
          return "CP";
        case "credito_habitacao":
          return "CH";
        case "seguro_de_vida":
          return "SS";
        default:
          return "CC";
      }
    },
    async prepareComponent() {
      await this.collectLeadData();
      await this.collectExtraLeadData();
      await this.getAllContentDetails();
    },
  },
  created: async function () {
    Object.assign(this.$data, initialState());
    await this.prepareComponent();
  },
  computed: {
    completeLead() {
      let lead = this.lead;

      const items = [
        "prit_data",
        "sect_data",
        "operation_data",
        "responsabilities",
        "comercial_assessment",
        "observations",
        "owner",
        "escritura",
        "avaliacao",
        "seguros",
        "banco",
      ];

      for (let i of items) {
        if (lead[i] == null || lead[i] == undefined) {
          if (typeof leadComplete[i] == "string") lead[i] = leadComplete[i];
          else if (Array.isArray(leadComplete[i])) lead[i] = [];
          else lead[i] = Object.assign({}, leadComplete[i]);
        }
      }
      return lead;
    },
    observations() {
      let obs = this.lead.observations;
      let arr = [];

      if (typeof obs == "string" && obs != "") {
        arr.push({
          mensagem: obs,
          data: "2021-02-01T00:00:00",
        });
      } else if (typeof obs == "object" && obs != null && obs.length > 0) {
        arr = obs.map((i) => Object.assign({}, i));
      }

      return arr;
    },
  },
  watch: {
    lead: async function (newLead) {
      Object.assign(this.$data, initialState());
      this.lead = newLead;
      await this.prepareComponent();
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 40px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

h2 {
  margin-left: 40px;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.h2-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  margin-bottom: -6px;
}

.container {
  margin: 10px 0px;
  max-width: 100%;
}

.colunas {
  padding: 20px 0;
  margin-top: 10px;
}

.colunas-interiores {
  padding: 20px 40px;
}

.v-list-item {
  min-height: 10px;
  padding: 0;
  margin-bottom: 0 !important;
}

.v-list-item__content {
  padding: 8px 0;
}

.v-list-item__title {
  font-size: 14px;
}

h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
.v-item-group {
  padding: 0 30px;
}
.v-list-item__icon {
  margin-right: 10px !important;
  margin-left: 20px;
}

.timeline-date {
  font-size: 14px;
  padding-bottom: 5px !important;
}

.timeline-container {
  margin-top: 20px;
  padding-right: 25px;
}

.sub-titulos-ficha {
  margin-left: 0px;
  margin-bottom: 5px;
}

.sub-titulo-bold {
  text-transform: capitalize;
}
.dependentes:not(:last-of-type) {
  margin-bottom: 8px !important;
}
</style>
   