import {
  titular_cc,
  ss_resume,
  titular_ch,
  text_obs_ch,
  text_obs_cc,
  text_obs_ss,
  operation_data_cc,
  operation_data_ch,
  ss_seguros,
  escritura_ch,
  banco_ch,
} from '@/helpers/LeadInsertData/orderingArray.js';

import { leadComplete } from '@/helpers/LeadInsertData/modelCompleteLead.js';

import { Input } from '@/helpers/LeadInsertData/baseContent.js';

import {
  librarySelector,
  libraryTable,
  libraryDatePicker,
  libraryHourPicker,
  libraryFamily,
  libraryLevel,
  libraryProps,
  libraryTextArea,
  libraryCheckbox,
  nameOfFamily,
  libraryGeral,
} from '@/helpers/LeadInsertData/libraries.js';

import {
  contentCH,
  contentCC,
  contentSS,
  contentCP,
} from '@/helpers/LeadInsertData/contentStructure.js';

import store from '../../store';

const populateItemstHelper = (type) => {
  switch (type) {
    case 'CH':
      return contentCH();
    case 'CC':
      return contentCC();
    case 'CP':
      return contentCP();
    case 'SS':
      return contentSS();
    default:
      return contentCC();
  }
};

const elementsInput = (lead, value) => {
  let family = libraryFamily[lead];
  let options = [];

  let type = 'text';
  if (Object.keys(librarySelector).includes(lead)) type = 'select';
  else if (Object.keys(libraryDatePicker).includes(lead)) type = 'datePicker';
  else if (Object.keys(libraryHourPicker).includes(lead)) type = 'hourPicker';
  else if (Object.keys(libraryTable).includes(lead)) type = 'table';
  else if (Object.keys(libraryTextArea).includes(lead)) type = 'textarea';
  else if (Object.keys(libraryCheckbox).includes(lead)) type = 'checkbox';

  if (type == 'select') options = librarySelector[lead];

  let level = libraryLevel[lead];

  let info = new Input(lead, family, value, type, options, level);

  return info;
};

const completeLeadData = (dados) => {
  let lead = JSON.parse(JSON.stringify(dados));

  // se a lead tiver itens null, substitui pelos valores da lead modelo
  const items = ['prit_data','sect_data','operation_data','responsabilities','comercial_assessment','owner','escritura','avaliacao','seguros','banco'];

  for(let i of items) {
    if(lead[i] == null || lead[i] == undefined) {
      if(typeof leadComplete[i] == 'string') lead[i] = leadComplete[i];
      else lead[i] = Object.assign({},leadComplete[i]);
    }
  }

  // preencher campos da tab primeiro titular
  const fields = ["nome","email","telefone"];
  for(let i of fields) {
    if(lead.prit_data[i] == "") lead.prit_data[i] = lead[i] || "";
  }

  // preencher campos com dados auxiliares
  let type = lead.produto;

  let auxArr = [];

  if(type == 'seguro_de_vida') {
    auxArr = [
      {1: "seguros", 2: "capital_em_divida", 3: "aux_1"},
      {1: "prit_data", 2: "data_nascimento", 3: "aux_2"},
      {1: "sect_data", 2: "data_nascimento", 3: "aux_3"},
    ]
  } else if(type == 'credito_pessoal') {
    auxArr = [
      {1: "operation_data", 2: "valor_do_financiamento___em_divida", 3: "aux_1"},
      {1: "operation_data", 2: "prazo_pretendido", 3: "aux_2"},
    ]
  } else if(type == 'credito_consolidado') {
    auxArr = [
      {1: "operation_data", 2: "valor_do_financiamento___em_divida", 3: "aux_1"},
      {1: "operation_data", 2: "prazo_pretendido", 3: "aux_2"},
      {1: "prit_data", 2: "incumprimento", 3: "aux_3"},
      {1: "prit_data", 2: "vinculo", 3: "aux_4"},
      {1: "prit_data", 2: "rendimento_liquido_mensal", 3: "aux_5"},
      {1: "prit_data", 2: "tipo_de_habitacao", 3: "aux_6"},
      {1: "prit_data", 2: "titulares_total", 3: "aux_7"},
      {1: "prit_data", 2: "data_nascimento", 3: "aux_8"},
      {1: "sect_data", 2: "data_nascimento", 3: "aux_9"},
      {1: "prit_data", 2: "comment", 3: "aux_10"},
    ]
  }

  for(let i of auxArr) {
    if(lead[i[1]][i[2]] == "" || lead[i[1]][i[2]] == undefined) lead[i[1]][i[2]] = lead[i[3]] || "";
  }

 return lead;
}

const groupLeadDetailsHelper = (dados) => {
  let obj = completeLeadData(dados);
  let lead = JSON.parse(JSON.stringify(obj));
  
  let data = {
    geral: [],
    prit_data: [],
    sect_data: [],
    // observations: [],
    operation_data: [],
    responsabilities: [],
    escritura: [],
    avaliacao: [],
    seguros: [],
    text: [],
    owner: [],
    banco: [],
    banco_selector: [],
    comercial_assessment: [],
  };

  //Arrays para preencher texto em Lead Details:
  let text_lead_detail = {
    prit_data: [
      {
        title: 'Dados Pessoais',
        cols: '4',
        content: [],
      },
      {
        title: 'Rendimentos',
        cols: '4',
        content: [],
      },
      {
        title: 'Profissão',
        cols: '4',
        content: [],
      },
      {
        title: 'Dados de Crédito',
        cols: '4',
        content: [],
      },
    ],
    sect_data: [
      {
        title: 'Dados Pessoais',
        cols: '4',
        content: [],
      },
      {
        title: 'Rendimentos',
        cols: '4',
        content: [],
      },
      {
        title: 'Profissão',
        cols: '4',
        content: [],
      },
      {
        title: 'Dados de Crédito',
        cols: '4',
        content: [],
      },
    ],
    operation_data: [
      {
        title: 'Operações',
        cols: '12',
        content: [],
      },
    ],
    responsabilities: [
      {
        title: 'Responsabilidades',
        cols: '12',
        content: [],
      },
    ],
    banco: [
      {
        title: 'Banco',
        cols: '12',
        content: [],
      },
    ],
    avaliacao: [
      {
        title: 'Avaliação',
        cols: '12',
        content: [],
      },
    ],
    escritura: [
      {
        title: 'Escritura',
        cols: '12',
        content: [],
      },
    ],
    seguros: [
      {
        title: 'Seguradora',
        cols: '12',
        content: [],
      },
    ],
  };

  for (let item in lead) {
    if (!libraryProps.includes(item)) {
      if (typeof lead[item] == 'string' || item == 'owner') {
        let value = '';
        if (lead[item] != null) value = lead[item];

        let info = elementsInput(item, value);

        if (item != 'produto') {
          data[item].push(info);
        } else {
          data['text'].push(info);
        }
      } else {
        let info = '';
        if (item != 'banco') {
          for (let i in lead[item]) {
            let value = '';
            if (lead[item][i] != null) value = lead[item][i];

            info = elementsInput(i, value);

            data[item].push(info);
            if (item != 'sect_data' && item != 'prit_data') {
              data['text'].push(info);
            }

            let valueFamily = familyPosition(item, info);
            info.cols = 4;
            text_lead_detail[item][valueFamily].content.push(info);
          }
        } else {
          let value = '';
          if (lead[item] != null) value = lead[item];

          info = elementsInput(item, value);

          data[item].push(info);
        }
      }
    } else {
      if (libraryGeral.includes(item)) {
        let value = '';
        if (lead[item] != null) value = lead[item];

        let info = elementsInput(item, value);
        info.cols = '3';
        data['text'].push(info);
        data['geral'].push(info);
      }
    }
  }

  //Adicionar campos em Aberto:
  // console.log(data['banco'])
  data['banco_selector'].push({
    title: 'Banco',
    value: '',
    type: 'select',
    options: [
      'Santader',
      'CGD',
      'Novo Banco',
      'BPI',
      'CTT',
      'UCI',
      'Bankinter',
      'Eurobic',
      'Abanca',
    ],
    level: 3,
    family: 17,
  });

  data['banco_selector'].push({
    title: 'Balcao',
    value: '',
    type: 'text',
    level: 3,
    family: 17,
  });

  data['banco_selector'].push({
    title: 'Data de Envio',
    value: '',
    type: 'datePicker',
    level: 3,
    family: 17,
  });

  data['banco_selector'].push({
    title: 'Notas',
    value: '',
    type: 'text',
    level: 3,
    family: 17,
  });

  //Adicionar o btn plus
  data['banco_selector'].push({
    title: 'Add',
    type: 'btn',
    icone: 'mdi-plus',
    color: 'rgb(196, 194, 44)',
    dark: true,
    level: 3,
    family: 17,
  });

  data.text_lead_detail = text_lead_detail;
  // console.log('2' , text_lead_detail)
  //Verificar se o preenchimento das leads está completo:
  for (let i in data) {
    if (
      i != 'banco_selector' &&
      i != 'text_lead_detail' &&
      i != 'banco' &&
      i != 'geral'
    ) {
      //Caso true não está completo:
      if (
        data[i].length < Object.keys(leadComplete[i]).length ||
        data[i].length == 0
      ) {
        //Passo a passo para ver qual é a caracteristica que falta:
        if (typeof leadComplete[i] != 'string') {
          for (let j in leadComplete[i]) {
            //Lista de data map não inclui a característica:
            if (
              !data[i].map((e) => e.title).includes(j) ||
              data[i].length == 0
            ) {
              //Obter apenas com os valores da lead Complete pois não há informação da API:
              let str = leadComplete[i][j];
              let info = elementsInput(j, str);
              data[i].push(info);

              if (i != 'text') {
                let valueFamily = familyPosition(i, info);
                info.cols = 4;

                text_lead_detail[i][valueFamily].content.push(info);
              }
            }
          }
        } else {
          let str = leadComplete[i];
          let info = elementsInput(i, str);
          data[i].push(info);
          console.log('oi', info )
        }
      }
    }
  }

  store.commit('SET_ARRAY_DATA', data);

  return data;
};

const familyPosition = (prop, info) => {
  if (prop == 'prit_data' || prop == 'sect_data') {
    if (info.family == 1 || info.family == 2) return 0;
    else if (info.family == 3) return 2;
    else if (info.family == 4) return 1;
    else if (info.family == 7 || info.family == 8) return 3;
    else return 0;
  } else return 0;
};

//Sort Number
const orderByTitle = (param, array) => {
  const sortIndexNumber = (a, b) => {
    let index = array.findIndex((e) => e.includes(a.title));
    index = index >= 0 ? index : 1000;

    let index_b = array.findIndex((e) => e.includes(b.title));
    index_b = index_b >= 0 ? index_b : 1000;

    if (index < index_b) {
      return -1;
    }

    if (index > index_b) {
      return 1;
    }

    return 0;
  };

  let new_array = param;

  if (new_array != null && new_array != undefined)
    //Sort e filtro:
    return new_array
      .sort(sortIndexNumber)
      .filter((j) => array.includes(j.title));
  else {
    new_array = [];
    return new_array;
  }
};

const rearrangeContent = (desc) => {
  let content = {
    obs: {
      firstRow: 'text',
      orderArray: text_obs_cc,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    prit: {
      firstRow: 'prit_data',
      orderArray: titular_cc,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    sect: {
      firstRow: 'sect_data',
      orderArray: titular_cc,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    operation_data: {
      firstRow: 'operation_data',
      orderArray: operation_data_cc,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    responsabilities: {
      firstRow: 'responsabilities',
      orderArray: '',
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    owner: {
      firstRow: 'owner',
      orderArray: '',
      secondRow: '',
      orderArraySecondRow: '',
      // obs: false,
    },
    obs_ss: {
      firstRow: 'text',
      orderArray: text_obs_ss,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    prit_ss: {
      firstRow: 'prit_data',
      orderArray: ss_resume,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    sect_ss: {
      firstRow: 'sect_data',
      orderArray: ss_resume,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    seguros: {
      firstRow: 'seguros',
      orderArray: ss_seguros,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    prit_ch: {
      firstRow: 'prit_data',
      orderArray: titular_ch,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    sect_ch: {
      firstRow: 'sect_data',
      orderArray: titular_ch,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    operation_data_ch: {
      firstRow: 'operation_data',
      orderArray: operation_data_ch,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    comercial_assessment: {
      firstRow: 'comercial_assessment',
      orderArray: '',
      secondRow: '',
      orderArraySecondRow: '',
      // obs: false,
    },
    avaliacao: {
      firstRow: 'avaliacao',
      orderArray: '',
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    escritura: {
      firstRow: 'escritura',
      orderArray: escritura_ch,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    obs_ch: {
      firstRow: 'text',
      orderArray: text_obs_ch,
      secondRow: '',
      orderArraySecondRow: '',
      // obs: true,
    },
    banco: {
      firstRow: 'banco_selector',
      orderArray: '',
      orderArraySecondRow: '',
      secondRow: 'banco',
      // obs: false,
    },
    
  };

  return content[desc];
};

//Set all types of text field in item content

const populateContentHelper = (items, lead) => {
  let array = JSON.parse(JSON.stringify(items));
  let dados = JSON.parse(JSON.stringify(lead));

  for (let item of array) {
    if (item.desc != 'docs' && !item.desc.includes('obs')) {
      //Dados que serão buscados:
      let content = rearrangeContent(item.desc);

      let newArray = JSON.parse(JSON.stringify(dados[content.firstRow]));

      if (content.orderArray != '') {
        newArray = orderByTitle(newArray, content.orderArray);
      }

      //Arrumar as colunas:
      newArray = Input.cols(newArray);

      //Primeira Linha:
      item.content = newArray.newArray;
      item.family = newArray.amountFamily;

      //Segunda Linha:
      if (content.secondRow != '') {
        newArray = JSON.parse(JSON.stringify(dados[content.secondRow]));

        if (content.orderArraySecondRow != '') {
          newArray = orderByTitle(newArray, content.orderArraySecondRow);
        }

        //Arrumar as colunas:
        newArray = Input.cols(newArray);

        item.secondRow = newArray.newArray;
        item.familySecondRow = newArray.amountFamily;
      }

      //Incluir as observações em cada TAB:
      // if (content.obs) {
      //   item.secondRow = dados.observations;
      //   item.familySecondRow.push(
      //     nameOfFamily.findIndex((e) => e == 'Observações') + 1
      //   );
      // }

    } else if(item.desc.includes('obs')) {
      // Preencher conteúdo da tab Resumo
      for(let el of item.content) {
        el.content = dados[el.desc];
      }
      
      // adicionar observação
      // item.secondRow = dados.observations;
      // item.familySecondRow.push(
      //   nameOfFamily.findIndex((e) => e == 'Observações') + 1
      // );
    }
  }

  return array;
};

export {
  orderByTitle,
  groupLeadDetailsHelper,
  populateItemstHelper,
  populateContentHelper,
};
