//Bibliotecas de tipos conforme o output da API:

//import store from '../../store';

let consultores = [
  'Teresa Braga Costa',
  'Pedro Victor',
  'Bernardo Sotomayor',
  'Raquel Rocha',
  'Fátima Barbosa',
  'Alexandre Jardim',
  'Valter Ponte',
];

//campos de preenchimento prioritário
const priorityInput = ['nome', 'profissao', 'nif', 'data_nascimento'];

//***************Bibliotecas de Tipo
const librarySelector = {
  titulo: ['Sr', 'Sr(a)', 'Dr', 'Dr(a)', 'Eng', 'Eng(a)'],
  tipo_de_habitacao: [
    'Própria',
    'Arrendada',
    'Alojamento sem encargos',
    'Familiares',
    'Hipoteca (CH)',
  ],
  estado_civil: [
    'Casado',
    'Solteiro',
    'Divorciado',
    'Unido de Facto',
    'Viuvo',
    'Separado',
  ],
  tipo_de_documento: [
    'BI',
    'CARTÃO CIDADÃO',
    'PASSAPORTE',
    'DOC. IDENTIF MILITAR',
    'TITULO RESIDÊNCIA',
    'OUTRO',
  ],
  vinculo: [
    'Efetivo',
    'A termo Certo',
    'A Termo Incerto',
    'ENI',
    'Funcionário Público',
    'Recibos Verdes',
    'Conta própria',
    'Reformado',
    'Desempregado'
  ],
  residencia: ['Residente', 'Não Residente'],
  operacao: ['Consolidado', 'Pessoal', 'Habitação', 'Automóvel', 'Obras'],
  tipo_de_operacao: [
    'Aquisição',
    'Transferência',
    'Transferência Com Incremento',
    'Auto-construção',
    'Mútuo com Hipoteca',
    'Aquisição + Obras',
    'Transferência + Obras'
  ],
  produto_seguradora: [
    'Vida',
    'Automóvel',
    'Vida Crédito',
    'Multirriscos',
    //'AT',
    'Saúde',
    'Outro',
  ],
  banco_principal: [
    'Abanca',
    'BCTT',
    'BST',
    'CGD',
    'EuroBic',
    'NB',
    'UCI',
    'BPI',
    'BK',
    'Outro',
  ],
  banco_atual: [
    'Abanca',
    'BCTT',
    'BST',
    'CGD',
    'EuroBic',
    'NB',
    'UCI',
    'BPI',
    'BK',
    'BCP',
    'ActivoBank',
    'Montepio',
    'Crédito Agrícola' ,
    'BBVA',

  ],
  tipo_de_taxa: [
    'Fixa',
    'Mista',
    'Variável',
  ],
  banco_principal_escritura: [
    'Abanca',
    'BCTT',
    'BST',
    'CGD',
    'EuroBic',
    'NB',
    'UCI',
    'BPI',
    'BK',
    'Outro',
  ],
  seguradora_futura: [
    'April',
    'Real Vida',
    'Tranquilidade',
    'Zurich',
    'Prevóir',
    'MGEN',
    'Asisa',
    'Liberty',
    'Lusitânia',
    'Una',
    'Outro',
  ],
  owner: consultores,
};

const libraryTable = {
  banco: '',
};

const libraryCheckbox = {
  fiador: '',
  adesao_ao_seguro: '',
  suficiente: '',
};

const libraryDatePicker = {
  data_nascimento: '',
  empregado_desde: '',
  validade_do_documento: '',

  data_da_carta_de_conducao: '',
  data_da_escritura: '',
  data_da_contratacao: '',
};

const libraryHourPicker = {
  hora_da_escritura: '',
};

const libraryTextArea = {
  comercial_assessment: '',
  // observations: '',
};

const libraryFamily = {
  // 'aux_1',
  // 'telefone',
  // 'dt_created',
  // 'current_step',
  // 'current_step_id',
  // 'last_interation',
  // 'data_inicio_processo',
  // 'lead_details',
  // 'callback_date',

  titulo: 1,
  nome: 1,
  estado_civil: 1,
  data_nascimento: 1,
  idade: 1,
  telefone: 1,
  email: 1,
  nacionalidade: 1,
  naturalidade: 1,
  dependentes: 1,

  tipo_de_documento: 2,
  numero_do_documento: 2,
  validade_do_documento: 2,
  nif: 2,

  entidade_patronal: 3,
  empregado_desde: 3,
  vinculo: 3,
  profissao: 3,
  sector: 3,

  rendimento_liquido_mensal: 4,
  rendimentos_variaveis: 4,
  rendimentos_prediais: 4,
  pensao_de_alimentos: 4,
  rendimentos_nao_comprovaveis: 4,
  outros_rendimentos: 4,
  proveniencia_de_outros_rendimentos: 4,
  despesas_do_agregado: 4,
  rendimento_nao_comprovaveis: 4,
  outros_creditos___rendimento: 4,
  titulares_total: 4,
  incumprimento: 4,
  comment: 4,

  iban: 5,
  ano_de_abertura_da_conta: 5,

  tipo_de_habitacao: 6,
  morada: 6,
  codigo__postal: 6,
  localidade: 6,
  vive_nesta_casa_desde: 6,
  residencia: 6,

  operacao: 7,
  prazo_pretendido: 7,
  valor_do_financiamento___em_divida: 7,
  valor_da_avaliacao_do_imovel: 7,
  banco_atual: 7,
  
  adesao_ao_seguro: 7,
  liquidez_adicional: 7,
  data_da_contratacao: 7,
  valor_da_aquisicao: 7,
  tipo_de_taxa: 7,
  spread_actual: 7,
  valor_do_multiopcoes___obras: 7,
  tipo_de_operacao: 7,
  fiador: 7,

  renda___prestacao_ch_1t: 8,
  pensao_de_alimentos_1t: 8,
  renda___prestacao_ch_2t: 8,
  pensao_de_alimentos_2t: 8,
  resp: 8,

  owner: 9,

  comercial_assessment: 10,

  // observations: 11,

  data_da_escritura: 12,
  hora_da_escritura: 12,
  local_da_escritura: 12,
  nif_escritura: 12,
  seguro_escritura: 12,
  envio_de_convite: 12,
  banco_principal_escritura: 12,
  agencia: 12,
  valor_escritura: 12,
  avaliacao_do_imovel: 12,
  valor_do_orcamento: 12,
  outros_creditos: 12,
  localizacao_imovel: 12,
  tipo_de_operacao_escritura: 12,
  montante_a_financiar: 12,
  prazo_pretendido_escritura: 12,

  resultado_da_avaliacao: 13,
  suficiente: 13,
  notas_avaliacao:13,

  //Seguradora

  seguradora_atual: 14,
  premio_atual: 14,
  capital_em_divida: 14,
  spread_actual_seguradora: 14,
  produto_seguradora: 14,

  seguradora_futura: 15,
  premio_proposto: 15,

  banco_principal: 16,
  data_de_duracao_do_emprestimo: 16,
  cobertura_pretendida: 16,

  //Seguro de Saúde
  n_de_pessoas_asseguradas: 17,

  //Automóvel
  data_da_carta_de_conducao: 18,
  matricula_do_carro: 18,
  marca_do_automovel: 18,
  modelo_do_automovel: 18,
  versao: 18,
  tipo_de_cobertura: 18,

  //Vida:
  data_de_nascimento_seguro: 19,
  valor_a_segurar: 19,

  //Multirriscos:
  morada_completa_seguros: 20,
  tipo_de_imovel: 20,
  ano_de_construcao: 20,
  area_bruta_privativa: 20,
  n_de_casas_de_banho: 20,
  n_de_assoalhos: 20,

  produto: 21,
  banco: 22,
  // banco_principal_banco: 22,

  //Banco selector 17
};

//***************Bibliotecas de Familia
const libraryLevel = {
  titulo: 3,
  nome: 1,
  estado_civil: 3,
  data_nascimento: 3,
  idade: 3,
  telefone: 3,
  email: 2,
  nacionalidade: 3,
  naturalidade: 3,
  dependentes: 3,

  tipo_de_documento: 3,
  numero_do_documento: 3,
  validade_do_documento: 3,
  nif: 3,

  entidade_patronal: 3,
  empregado_desde: 3,
  vinculo: 3,
  profissao: 3,
  sector: 3,

  rendimento_liquido_mensal: 3,
  rendimentos_variaveis: 3,
  rendimentos_prediais: 3,
  pensao_de_alimentos: 3,
  rendimentos_nao_comprovaveis: 3,
  outros_rendimentos: 3,
  proveniencia_de_outros_rendimentos: 3,
  despesas_do_agregado: 3,
  rendimento_nao_comprovaveis: 3,
  outros_creditos___rendimento: 3,
  titulares_total: 3,
  incumprimento: 3,
  comment: 3,

  iban: 3,
  ano_de_abertura_da_conta: 3,

  tipo_de_habitacao: 3,
  morada: 2,
  codigo__postal: 3,
  localidade: 3,
  vive_nesta_casa_desde: 3,
  residencia: 3,

  operacao: 3,
  prazo_pretendido: 3,
  valor_do_financiamento___em_divida: 3,
  adesao_ao_seguro: 3,
  valor_da_avaliacao_do_imovel: 3,
  liquidez_adicional: 3,
  data_da_contratacao: 3,
  valor_da_aquisicao: 3,
  tipo_de_taxa: 3,
  spread_actual: 3,
  valor_do_multiopcoes___obras: 3,
  tipo_de_operacao: 3,
  fiador: 3,

  renda___prestacao_ch_1t: 3,
  pensao_de_alimentos_1t: 3,
  renda___prestacao_ch_2t: 3,
  pensao_de_alimentos_2t: 3,
  resp: 3,

  owner: 3,

  comercial_assessment: 3,

  // observations: 3,
  data_da_escritura: 3,
  hora_da_escritura: 3,
  local_da_escritura: 3,
  nif_escritura: 3,
  nif_escritura_escritura: 3,
  envio_de_convite: 3,
  banco_principal_escritura: 3,
  banco_atual: 3,
  agencia: 3,
  valor_escritura: 3,
  // avaliacao_do_imovel: 3,
  valor_do_orcamento: 3,
  outros_creditos: 3,
  localizacao_imovel: 3,
  tipo_de_operacao_escritura: 3,
  montante_a_financiar: 3,
  // prazo_pretendido_escritura: 3,

  resultado_da_avaliacao: 3,
  suficiente: 3,
  notas_avaliacao: 3,

  seguradora_atual: 3,
  premio_atual: 3,
  capital_em_divida: 3,
  spread_actual_seguradora: 3,
  produto_seguradora: 3,

  seguradora_futura: 3,
  premio_proposto: 3,

  banco_principal: 3,
  data_de_duracao_do_emprestimo: 3,
  cobertura_pretendida: 3,

  n_de_pessoas_asseguradas: 3,

  data_da_carta_de_conducao: 3,
  matricula_do_carro: 3,
  marca_do_automovel: 3,
  modelo_do_automovel: 3,
  versao: 3,
  tipo_de_cobertura: 3,

  //Vida:
  data_de_nascimento_seguro: 3,
  valor_a_segurar: 3,

  //Multirriscos:
  morada_completa_seguros: 2,
  tipo_de_imovel: 3,
  ano_de_construcao: 3,
  area_bruta_privativa: 3,
  n_de_casas_de_banho: 3,
  n_de_assoalhos: 3,

  produto: 3,
};

//Não Serão inclusas:
const libraryProps = [
  'lead_process_id',
  'process_id',
  'lead_id',
  'nome',
  'email',
  'aux_1',
  'telefone',
  'aux_2',
  'aux_3',
  'aux_4',
  'aux_5',
  'aux_6',
  'aux_7',
  'aux_8',
  'aux_9',
  'aux_10',
  'aux_11',
  'aux_12',
  'dt_created',
  'current_step',
  'current_step_id',
  'username',
  'contact_id',
  'last_interation',
  'data_inicio_processo',
  'lead_details',
  'callback_date',
  'produto',
  'observations',
];

const nameOfFamily = [
  'Dados Pessoais', //1
  'Documentos', //2
  'Emprego', //3
  'Rendimento', //4
  'Conta Bancária', //5
  'Habitação', //6
  'Operação', //7
  'Renda', //8
  'Owner', //9
  'Comercial Assesment', //10
  'Observações', //11
  'Escritura', //12
  'Avaliação', //13
  'Dados atuais para Seguro', //14
  'Dados propostos para Seguro', //15
  'Vida Crédito', //16
  'Seguro de Saúde', //17
  'Automóvel', //18
  'Seguro de Vida', //19
  'Multirriscos', //20
  'Produto', //21
  'Banco', //22
];

const libraryGeral = [
  'nome',
  'email',
  'aux_1',
  'telefone',
  'current_step',
  'produto',
];

const productInputs = {
  automoveisSeguradora: [
    "matricula_do_carro",
    "marca_do_automovel",
    "modelo_do_automovel",
    "versao",
    "tipo_de_cobertura"
  ],
  saudeSeguradora: [
    "n_de_pessoas_asseguradas",
    "dependentes__seguros"
  ],
  vidaCreditoSeguradora: [
    "banco_principal",
    "data_de_duracao_do_emprestimo",
    "cobertura_pretendida"
  ],
  vidaSeguradora: [
    "data_de_nascimento_seguro",
    "valor_a_segurar"
  ],
  multirriscosSeguradora: [
    "morada_completa_seguros",
    "tipo_de_imovel",
    "ano_de_construcao",
    "area_bruta_privativa",
    "n_de_casas_de_banho",
    "n_de_assoalhos"
  ]
}

export {
  libraryGeral,
  librarySelector,
  libraryTable,
  libraryDatePicker,
  libraryHourPicker,
  libraryFamily,
  libraryLevel,
  libraryProps,
  libraryTextArea,
  libraryCheckbox,
  nameOfFamily,
  priorityInput,
  productInputs
};
